@font-face {
  font-family: 'BebasNeuePro-Bold';
  src:  url('/assets/fonts/BebasNeuePro-Bold.woff2') format('woff2');
}

// @font-face {
//   font-family: 'BebasNeuePro-Middle';
//   src:  url('/assets/fonts/BebasNeuePro-Middle.woff2') format('woff2');
// }

// @font-face {
//   font-family: 'BebasNeue';
//   src:  url('/assets/fonts/BebasNeue-Regular.woff2') format('woff2');
// }

body {
  color: #c0c0c0;
  background-color: #131313;
  font-family: 'Georgia', serif;
  min-height: 70vh;
}

p {
  font-size: 18px;
  line-height: 29px;
}

h1, .post-title, .post-content h1 {
  font-size: 22px;
}

blockquote {
  font-size: 16px;
  line-height: 23px;
}

a {
  color: #d28938;
  text-decoration: none;
}
a:visited {
  color: #b57631;
}
a:hover {
  color: #d28938;
  text-decoration: underline;
}

.site-header {
  position: inherit;
  background-color: #e0e0e0;
  border-style: none;
  padding-top: 33px;
  padding-bottom: 23px;
  line-height: inherit;
  a { color: #131313; }
  a:visited { color: #131313; }
  a:hover { color: #131313; }
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media screen and (max-width: 520px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .wrapper:after {
    display: none;
  }
}

.site-nav .nav-item {
  color: #131313;
}

.site-title {
  font-family: BebasNeuePro-Bold;
  font-size: 57px;
  line-height: 50px;
  text-transform: uppercase;
}
.site-title:hover {
  text-decoration: none;
}

.site-nav {
  margin-top: 10px;
  .nav-item {
    font-size: 20px;
    color: #131313;
  }
}

.site-footer {
  border-top: 0;
  background-color: #e0e0e0;
  a { color: #303030; }
  p { color: #303030; }
}

.footer-col-wrapper {
  flex-direction: column;
}

.footer-col {
  margin-bottom: 0px;
  &:first-child {
    padding-right: 0;
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
    padding-left: 0;
  }
}

.social-links {
  padding-top: 30px;
  padding-bottom: 80px;
}

.social-media-list {
  display: table;
  margin: 0;
}

.footer-col p {
  margin-bottom: 0px;
}

.footer-col ul {
  margin-bottom: 0px;
}

.front-content {
  padding-top: 13px;
  padding-bottom: 170px;
  h3 {
    color: #d1d1d1;
    font-size: 38px;
    font-family: BebasNeuePro-Bold;
    padding-top: 25px;
  }
  p {
    font-size: 18px;
    line-height: 26px;
    max-width: 535px;
  }
}

.page-content {
  padding-top: 38px;
  padding-bottom: 38px;
}

.post-content h3 {
  margin: 0px;
  padding: 0px;
}

.top-quote {
  color: #c5c5c5;
  font-size: 22px;
  line-height: 30px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 30px;
  max-width: 500px;
}

.top-quote-credit {
  font-size: 80%;
}

.quotes-credit {
  font-size: 90%;
}

.review-quotes {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}

.quotes-left, .quotes-right {
  font-size: 75%;
  max-width: 350px;
}

.quotes-left {
  padding-right: 30px;
}

h3.review-list-headline {
  margin: 0px;
  padding: 0px;
  margin-top: 30px;
  margin-bottom: 8px;
  font-size: 26px;
  line-height: 29px;
  a { color: #f0f0f0; }
  a:visited { color: #b8b8b8; }
  a:hover { color: #ffffff; }
}

.review-list-excerpt,
.review-list-excerpt p {
  font-size: 15px;
  line-height: 20px;
  max-width: 500px;
  margin-bottom: 15px;
}

h1.review-title {
  font-size: 28px;
  line-height: 29px;
  color: #f8f8f8;
  margin: 0;
  padding: 0;
  margin-bottom: 14px;
}

.review-content p {
  font-size: 18px;
  line-height: 27px;
  max-width: 550px;
}

.review-header {
  margin-bottom: 14px;
}

.review-footer {
  margin-top: 54px;
}

.post-header {
  text-align: inherit;
  border-bottom: none;
  padding-bottom: 0;
}

.youtube-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  // padding-bottom: 56.25%;
  padding-bottom: 75%;
}

.youtube-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
